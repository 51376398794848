import React from "react";

//add max or ending value if needed
function ProgressBar({ title, index = 1, total_question }) {
  return (
    <div className="question-progress-bar-cover">
      <div className="head-question-progress-bar">
        <div className="text-question-progress">{title}</div>
        <div className="counter-question-progress">{index + 1} of {total_question}</div>
      </div>
      <div className="cover-question-progress-bar">
        <div
          className="question-progress-bar"
          style={{ width: `${(index + 1 ) * (100/total_question)}%` }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;
