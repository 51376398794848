import React, { useEffect, useState, useRef } from "react";
import { getActiveStep, selectActiveLayer, setActiveLayer } from "../../../../../../../redux/toolsSlice";
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import QnA from "./QnA";
import Header from "./Header";
import ProgressBar from "./ProgressBar";
import ButtonGroup from "./ButtonGroup";
import Button from "../../../../../../atoms/button/Button";
import { BackIcon, ForwardIcon } from "../../../../../../assets/Icons";
import { getResponseData, setResponseActiveStep } from "../../../../../../../redux/hubSlice";
import { options } from "sanitize-html";
import { HUB_ENDPOINTS } from "../../../../../utils/endpoints";
import { getMaterial } from "../../../../../../../redux/materialSlice";
import { http_post } from "../../../../../utils/hub_client";

export const MultipleChoiceLayerDefaults = (id) => {
  return {
    id: id,
    type: 'multiple_choice',
    multipleChoice: [
      { 
        id: uuidv4(),
        options: [
          {
            id: uuidv4(),
            value: "Option 1",
            isChecked: true
          },
          {
            id: uuidv4(),
            value: "Option 2",
            isChecked: false
          }
        ],
        isChecked: false 
      },
    ],
  };
}

const MultipleChoiceContent = ({ layer }) => {

  const [isEditing, setIsEditing] = useState(false);
  const [question, setQuestion] = useState(layer.question || []);
  const [options, setOptions] = useState(layer.multipleChoice || []);

  const questionRef = useRef();
  const dispatch = useDispatch();

  const layerObjConfig = useSelector((state) => getLayerObjConfig(state, layer), shallowEqual);
  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'multipleChoice',
      sectionTitle: 'Multiple Choice Element',
      values: [
        {
          id: 'multipleChoice',
          title: '',
          type: 'multipleChoice',
          value: layer.multipleChoice,
          direction: 'column'
        },
      ]
    }
  ];

  const EditElement = () => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
    setIsEditing(true);
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
    setIsEditing(false);
  }

  const updateText = () => {
    const newLayer = {
      ...layer,
      question: questionRef.current ? questionRef.current.value : layer.question,
      multipleChoice: options
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = { ...layer, ...layerObjConfig };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  useEffect(() => {
    setQuestion(layer.question);
  }, [layer]);

  useEffect(() => {
    setOptions(layer.multipleChoice || []);
  }, [layer]);

  const handleEditBlur = (e) => {
    removeFocusElement();
    setIsEditing(false);
    updateText();
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter' || e.key === "Escape") {
      removeFocusElement();
      setIsEditing(false);
      updateText();
    }
  };

  return (
    <>
      <div className="container-large-text" onBlur={removeFocusElement} onKeyDown={onPressEnter} onClick={EditElement}>
        {isEditing ? (
          <>
            {layer.type === "multiple_choice" ? 
              <MultipleChoiceContentView layer={layer} EditElement={EditElement} isEditing={isEditing} />
            :
            <>
              <input
                className="mr-2"
                ref={questionRef}
                defaultValue={layer.question}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                onBlur={handleEditBlur}
                onKeyDown={onPressEnter}
                style={{
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  width: '100%',
                  height: '100%',
                  fontWeight: '400',
                  fontSize: '15px'
                }}
                placeholder="Enter your question"
              />
              {options.map((data, index) => (
                <div key={data.id}>
                  <input
                    className="dropdown-data-checkbox mr-2"
                    type="checkbox"
                    checked={data.isChecked}
                    onChange={() => {
                      const updatedOptions = [...options];
                      updatedOptions[index].isChecked = !data.isChecked;
                      setOptions(updatedOptions);
                    }}
                  />
                  <input
                    type="text"
                    value={data.value}
                    onChange={(e) => {
                      const updatedOptions = [...options];
                      updatedOptions[index].value = e.target.value;
                      setOptions(updatedOptions);
                    }}
                    placeholder="Enter an option"
                  />
                  <br />
                </div>
              ))}
            </>
            }
          </>
        ) : (
          <MultipleChoiceContentView layer={layer}/>
        )}
      </div>
    </>
  )

}

export const MultipleChoiceContentView = ({ layer, EditElement, response}) => {
  const [currentQuestionIndex, SetCurrentQuestionIndex] = useState(0);
  const questionDetails = layer?.multipleChoice?.[currentQuestionIndex];
  const responseDetails = response ? response?.filter(ele => ele.question_id === questionDetails.id)?.[0] : null;
  const audience = window.location?.pathname?.split('/')?.[2] === "hub" || false;
  const responseData = useSelector((state) => getResponseData(state));
  const material = useSelector((state) => getMaterial(state));
  const activeStep = useSelector((state) => getActiveStep(state));
  const dispatch = useDispatch();
  
  const changeQuestion = (val) => {
    SetCurrentQuestionIndex(val);
  }

  const getCurrentTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset(); // Get timezone offset in minutes
    const timezoneOffset = -offset / 60; // Convert to hours (negative because offset is in minutes)
    const pad = (num) => (num < 10 ? `0${num}` : num);
  
    // Format date and time
    const year = now.getFullYear();
    const month = pad(now.getMonth() + 1);
    const date = pad(now.getDate());
    const hours = pad(now.getHours());
    const minutes = pad(now.getMinutes());
    const seconds = pad(now.getSeconds());
    const milliseconds = now.getMilliseconds().toString().padStart(3, '0');
  
    // Format timezone offset (e.g., +05:30)
    const absOffsetHours = pad(Math.floor(Math.abs(timezoneOffset)));
    const absOffsetMinutes = pad((Math.abs(offset) % 60));
    const offsetSign = timezoneOffset >= 0 ? '+' : '-';
  
    const formattedTime = `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${absOffsetHours}:${absOffsetMinutes}`;
    return formattedTime;
  };
  
  const selectAnswer = async (option) => {
    const url = HUB_ENDPOINTS.SAVE_RESPONSE(material.id);
    const answer = questionDetails?.options?.filter(ele => ele.isChecked)?.[0]?.value;
    const attemptDetail = {
      "id": layer?.id,
      "answer": option.value,
      "question": {
        "type": "multiple_choice",
        "result": answer === option.value,
        "description": questionDetails?.question,
      },
      "created_at": getCurrentTime(),
      "question_id": questionDetails?.id
    }    

    let newData;
    if (responseData && responseData.hasOwnProperty('response')) {
      newData = [...responseData.response]; // Create a copy of the previous response
      let step_index = newData.findIndex((e) => e.id === activeStep.id);
      if(step_index !== -1){
        newData = newData.map((item) =>{
          
          if(item.id === activeStep.id){
            let record = {...item};

            if(record?.attempt){
              let attemptIndex = record?.attempt?.findIndex((e) => e.question_id === questionDetails?.id);
              if(attemptIndex !== -1){
                record.attempt = record?.attempt?.map((ele)=> {                
                  if(ele.question_id === questionDetails.id){
                    return {
                      ...ele,
                      "answer": option.value,
                      "created_at": getCurrentTime()
                    }
                  }else{
                    return ele
                  }
                })                          
              }else{
                if(record?.attempt){
                  record["attempt"] = [...record["attempt"], attemptDetail];
                }else{
                  record["attempt"] = [...attemptDetail];
                }
              }
            }else{
              record["attempt"] = [{...attemptDetail}];
            }
            return record;
          }else{
            return item;
          }
        });                
      }else{
        newData.push({ "id": activeStep.id,
          "attempt": [ attemptDetail ],
          "completion": false
        });
      }
    } else {
      newData = [{ "id": activeStep.id, "attempt": [ attemptDetail ], "completion": false }];
    }

    const newResponse = {
      "minutes": responseData ? responseData.minutes : 0,
      "response": newData
    }    

    try {
      const res = await http_post(url, newResponse);
      const materials = res.data.data;
      dispatch(setResponseActiveStep(materials));
    } catch (error) {
      console.error("Error completing step:", error);
    }

  }
  
  return layer.type === "multiple_choice" ? 
    <>
      <div>
        {/* <Header /> */}
        <div className="template-preview">
          <ProgressBar title="Question" index={currentQuestionIndex} total_question={layer?.multipleChoice?.length || 1}/>
          {questionDetails && <QnA layer={questionDetails} response={responseDetails} audience={audience} onSelectoption={selectAnswer} />}
        </div>
        <div className="btn-area">
          <Button
            className="btn primary float-right"
            // onClick={nextPage}
            // configColors={colors}
            onClick={() => {
              changeQuestion(currentQuestionIndex - 1);
            }}
            disabled={currentQuestionIndex === 0}
          >
            <BackIcon />
            Back
          </Button>
          <Button
            className="btn primary float-right"
            onClick={() => {
              changeQuestion(currentQuestionIndex + 1);
            }}
            // configColors={colors}
            disabled={currentQuestionIndex === layer?.multipleChoice.length - 1}
          >
            Next
            <ForwardIcon />
          </Button>
        </div>
      </div>
    </>
    :
    <>
      <p
        tabIndex={0}
        onClick={EditElement}
        style={{
          fontWeight: '400',
          fontSize: '15px'
        }}
      >
        {layer.question}
      </p>
      {layer.multipleChoice && layer.multipleChoice.map((data) => (
        <div key={data.id}>
          <input
            className="dropdown-data-checkbox mr-2"
            type="checkbox"
            checked={data.isChecked}
            readOnly
          />
          {data.value}
          <br />
        </div>
      ))}
    </>
}

export const MultipleChoiceContentPreview = ({layer}) => {
  const [currentQuestionIndex, SetCurrentQuestionIndex] = useState(0);

  const changeQuestion = (val) => {
    SetCurrentQuestionIndex(val);
  }

  return(
    <div>
      <div className="template-preview">
        <ProgressBar title="Question" index={currentQuestionIndex} total_question={layer?.multipleChoice?.length || 1}/>
        <QnA layer={layer?.multipleChoice[currentQuestionIndex]}/>
      </div>
      <div className="btn-area">
        <Button
          className="btn primary float-right"
          // onClick={nextPage}
          // configColors={colors}
          onClick={() => {
            changeQuestion(currentQuestionIndex - 1);
          }}
          disabled={currentQuestionIndex === 0}
        >
          <BackIcon />
          Back
        </Button>
        <Button
          className="btn primary float-right"
          onClick={() => {
            changeQuestion(currentQuestionIndex + 1);
          }}
          // configColors={colors}
          disabled={currentQuestionIndex === layer?.multipleChoice.length - 1}
        >
          Next
          <ForwardIcon />
        </Button>
      </div>
    </div>
  );
}

export default MultipleChoiceContent;