import React, { useState, useEffect } from "react";
import EmptyContent from "./content/EmptyContent";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { addLayer, addStep, selectLayers, updateStep } from "../../../../../redux/stepsSlice";
import { getActiveStep } from "../../../../../redux/toolsSlice";
import Renderer from "./Renderer";
import { DateIcon } from "../../../../assets/Icons";
import ButtonGroup from "./content/fields/ButtonGroup";
import moment from 'moment';
import LargeTextContent from "./content/fields/LargeTextContent";

const Editor = () => {

  const dispatch = useDispatch();
  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const layers = useSelector((state) => selectLayers(state, activeStep.id));
  const canRender = layers && layers.filter(ele => ele.id !== 'main').length > 0;

  const [dueDate, setDueDate] = useState('');

  useEffect(() => {
    if (activeStep && activeStep.dueDate) {
      setDueDate(activeStep.dueDate);
    } else {
      setDueDate("");
    }
    if(stepId && layers && layers.length == 0){
      dispatch(addLayer({stepId: stepId,
          layer: {
          id: 'main',
          type: 'large_text',
          fontSize: 34,
          color: '#000000',
          fontFamily: 'Open Sans',
          content: "This is large title"
        }
      }))
    }  
  }, [activeStep]);

  useEffect(() => {
    const onSelect = (dateText) => {
      const dueDateValue = dateText.length > 0 ? dateText[0] : '';
      setDueDate(dueDateValue);
      const updatedStep = {
        ...activeStep,
        dueDate: dueDateValue ? dueDateValue.toISOString() : '',
      };
      dispatch(updateStep(updatedStep));
    };

    $(".calendar").datepick({
      onSelect,
      dateFormat: 'dd/mm/yyyy',
      minDate: new Date(),
    });

    return () => {
      $(".calendar").datepick('destroy');
    };
  }, [activeStep, dispatch]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'card',
    drop: () => ({ name: 'Editor' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))
  const isActive = canDrop && isOver
  let backgroundColor = '#CBD0D7'

  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (canDrop) {
    backgroundColor = 'darkkhaki'
  }

  return (
    <>
      <div>
        <div className="element-wrapper">
          {layers.filter(ele => ele.id == 'main')?.[0] &&
            <LargeTextContent layer={layers.filter(ele => ele.id == 'main')?.[0]}>
            </LargeTextContent>
          }
          <div className="flex-design-form">
            <DateIcon />
            <input
              style={{ width: 'auto' }}
              type="text"
              className="input-design-form calendar"
              placeholder="Set Due Date"
              value={dueDate ? moment(dueDate).format('DD/MM/YYYY') : ''}
            />
          </div>
        </div>
        <div ref={drop}>
          {canRender && (<Renderer layers={layers} />)}
          {!canRender && (<EmptyContent backgroundColor={backgroundColor} />)}
        </div>
        <div className="element-wrapper">
          <ButtonGroup />
        </div>
      </div>
    </>
  )
}

export default Editor;