import React from "react";

// pass Question and Options if needed
function QnA({layer, response, audience, onSelectoption}) {
  const { question, options } = layer;

  return (
    <div className="cover-question-form-block">
      <div className="list-question-form-block">
        <div className="item-list-question-form-block active">
          <div className="title-question-form-block">{question}</div>
          <div className="list-question-form">
            {options.map((option, index) => {
              return(
                <div className="item-list-question-form">
                  <div className="cover-radio-question-form" onClick={() => audience ? onSelectoption(option) : null}>
                    <div class="radio">
                      <label>
                        <input className="icon-question-form" type="radio" name="optradio" checked={audience ? response?.answer === option.value : option?.isChecked} />
                        <span className="text-radio-question-form">{option.value}</span>
                      </label>
                    </div>
                  </div>
                  {/* <div
                    className={`cover-radio-question-form ${option?.isChecked ? "correct-ans" : ""}`}
                    for="currently-employed"
                  >
                    <span className="icon-question-form"></span>
                    <span className="text-radio-question-form">{option.value}</span>
                  </div> */}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QnA;
