import React from 'react';
import { useSelector } from 'react-redux';
import { getMaterial } from '../../../redux/materialSlice';
import { getValue } from '../utils/tools';
import { getProfile } from '../../../redux/profileSlice';
import { getAudienceData, getCompanyDetails } from '../../../redux/hubSlice';
import { getCompany } from '../../../redux/companySlice';

const PreviewHeader = ({default_host}) => {

  const material = useSelector((state) => getMaterial(state));
  const current_user = useSelector((state) => getProfile(state));
  const audience_detail = useSelector((state) => getAudienceData(state));

  const hubUrl = window.location.pathname.split('/')[2];
  let companyDetail;

  if (hubUrl == 'hub') {
    companyDetail = useSelector((state) => getCompanyDetails(state));
  }
  else {
    companyDetail = useSelector((state) => getCompany(state));
  }

  const { config } = material;

  const defaultColors = {
    primary: '#3BA4B4',
    background: '#F6F8F9',
    text: '#363841',
    btnColor: '#3BA4B4',
    btnTextColor: '#FFFFFF',
    config: config
  };

  const headline = getValue(defaultColors, 'phc', 'phcHeadline');
  const subHeaderDescription = getValue(defaultColors, 'phc', 'phcSubHeaderDescription');
  const headerLogo = config ? getValue(defaultColors, 'phc', 'phcHeaderLogo') : '';
  const coverImage = config ? getValue(defaultColors, 'phc', 'phcCoverImage') : '';

  const haBGColor = getValue(defaultColors, 'ha', 'haBGColor');
  const haTextColor = getValue(defaultColors, 'ha', 'haTextColor');
  const haFont = getValue(defaultColors, 'ha', 'haFont');

  const CoverImageStyle = {
    backgroundImage: `url(${coverImage})`,
    backgroundSize: 'cover',
    height: '250px',
    backgroundPosition: 'center center',
  }

  const Bgstyle = {
    backgroundColor: haBGColor || 'white',
  }

  const textStyle = {
    color: haTextColor || 'black',
    fontFamily: haFont || 'Open Sans',
  }

  const handleSignIn = () => {
    const path = `/${companyDetail?.slug}/passwordless_sign_in`;
    window.location.href = path;
  }

  const isHub = hubUrl === "hub";
  const isSignedIn = isHub ? audience_detail.email : Object.keys(current_user).length > 0;

  const displayInfo = isHub
    ? audience_detail.email
    : current_user.name;

  const logo_image = headerLogo ? headerLogo : companyDetail?.logo_url

  return (
    <>
      {coverImage && <div className='cover-image' style={CoverImageStyle}></div>}
      <div className='preview-header' style={Bgstyle}>
        <div className='content-area'>
          {logo_image && <img src={logo_image} className='header-logo' />}
          <p className='material-title' style={textStyle}>{headline ? headline : material.name}</p>
          <p className='material-description' style={textStyle}>{subHeaderDescription ? subHeaderDescription : material.description}</p>
          {!default_host && <>          
            {isSignedIn ? (
              <>
                <br />
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                  <p style={textStyle}><b>Signed in as:</b><br /></p>
                  <div style={{ display: 'flex', alignItems: 'center', margin: "auto" }}>
                    <p style={textStyle} className='mr-2'>{displayInfo}</p>
                    <span style={textStyle}><i className="fa fa-arrow-circle-down" aria-hidden="true"></i></span>
                  </div>
                </div>
              </>
            ) : (
              <button
                type="button"
                className="btn ghost blue"
                style={{
                  width: 70,
                  borderRadius: 25,
                  display: "block",
                  margin: "auto"
                }}
                onClick={handleSignIn}
              >
                Sign in
              </button>
            )}
          </>}

          {/* {hubUrl === "hub" ?
          (audience_detail.email ?
            <>
              <br />
              <div style={{ textAlign: 'center', margin: 'auto' }}>
                <p style={textStyle}><b>Signed in as:</b><br /></p>
                <div style={{ display: 'flex', alignItems: 'center', margin: "auto" }}>
                  <p style={textStyle} className='mr-2'>{audience_detail.email}</p>
                  <span style={textStyle}><i class="fa fa-arrow-circle-down" aria-hidden="true"></i></span>
                </div>
              </div>
            </>
            :
            <button
              type="button"
              className="btn ghost blue"
              style={{
                width: 70,
                borderRadius: 25,
                display: "block",
                margin: "auto"
              }}>
              Sign in
            </button>
          )
          :
          <>
            {Object.keys(current_user).length > 0 ?
              <>
                <br />
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                  <p style={textStyle}><b>Signed in as:</b><br /></p>
                  <div style={{ display: 'flex', alignItems: 'center', margin: "auto" }}>
                    <p style={textStyle} className='mr-2'>{current_user.name}</p>
                    <span style={textStyle}><i class="fa fa-arrow-circle-down" aria-hidden="true"></i></span>
                  </div>
                </div>
              </>
              :
              <button
                type="button"
                className="btn ghost blue"
                style={{
                  width: 70,
                  borderRadius: 25,
                  display: "block",
                  margin: "auto"
                }}>
                Sign in
              </button>
            }
          </>
        } */}
          {/* <div hidden>
        <FontOption defaultValue={haFont} />
        </div> */}
        </div>
      </div>
    </>
  )
}

export default PreviewHeader