import React from "react";
import EmptyContent from "./content/EmptyContent";
import { ELEMENTS } from "./content/Consts";
import DropWrapper from "./content/DropWrapper";
import ElementWrapper from "./content/ElementWrapper";
import LargeTextContent from "./content/fields/LargeTextContent";
import BodyTextContent from "./content/fields/BodyTextContent";
import EmbedCodeContent from "./content/fields/EmbedCodeContent";
import VideoContent from "./content/fields/VideoContent";
import ImageContent from "./content/fields/ImageContent";
import AttachmentContent from "./content/fields/AttachmentContent.jsx";
import ShortAnswerContent from "./content/fields/ShortAnswerContent.jsx";
import LongAnswerContent from "./content/fields/LongAnswerContent.jsx";
import CheckboxContent from "./content/fields/CheckboxContent.jsx";
import MultiSelectContent from "./content/fields/MultiSelectContent.jsx";
import WalkthroughContent from "./content/fields/WalkthroughContent";
import FileUploadContent from "./content/fields/FileUploadContent.jsx";
import MultipleChoiceContent from "./content/fields/MultipleChoiceContent.jsx";

const Renderer = ({ layers }) => {

  const renderElement = (layer) => {
    switch (layer.type) {
      case ELEMENTS.LARGE_TEXT:
        return (
          <LargeTextContent
            layer={layer}>
          </LargeTextContent>
        )
      case ELEMENTS.SMALL_TEXT:
        return (
          <DropWrapper>
            <h2>{layer.content}</h2>
          </DropWrapper>
        )
      case ELEMENTS.BODY:
        return (
          <BodyTextContent
            layer={layer}>
          </BodyTextContent>
        )
      case ELEMENTS.SHORT_ANSWER:
        return (
          <ShortAnswerContent
            layer={layer}>
          </ShortAnswerContent>
        )
      case ELEMENTS.LONG_ANSWER:
        return (
          <LongAnswerContent
            layer={layer}>
          </LongAnswerContent>
        )
      case ELEMENTS.CHECKBOX:
        return (
          <CheckboxContent
            layer={layer}>
          </CheckboxContent>
        )
      case ELEMENTS.MULTIPLE_CHOICE:
        return (
          <MultipleChoiceContent
            layer={layer}>
          </MultipleChoiceContent>
        )
      case ELEMENTS.MULTI_SELECT:
        return (
          <MultiSelectContent
            layer={layer}>
          </MultiSelectContent>
        )
      case ELEMENTS.FILE_UPLOAD:
        return (
          <FileUploadContent
            layer={layer}>
          </FileUploadContent>
        )
      case ELEMENTS.QUOTES:
        return <blockquote>{layer.content}</blockquote>;
      case ELEMENTS.LONG_TEXT_FIELD:
        return <textarea>{layer.content}</textarea>;
      case ELEMENTS.INPUT_FIELD:
        return <input value={layer.content} />;
      case ELEMENTS.ATTACHMENT:
        return (
          <AttachmentContent
            layer={layer}>
          </AttachmentContent>
        );
      case ELEMENTS.TWO_COLUMNS:
        return <div>{layer.content}</div>;
      case ELEMENTS.THREE_COLUMNS:
        return <div>{layer.content}</div>;
      case ELEMENTS.IMAGE:
        return (
          <ImageContent
            layer={layer}
          ></ImageContent>
        );
      case ELEMENTS.VIDEO:
        return <VideoContent layer={layer} />;
      case ELEMENTS.WALKTHROUGH:
        return <WalkthroughContent layer={layer} />;
      case ELEMENTS.EMBEDDED_CODE:
        return (
          <EmbedCodeContent
            layer={layer}>
          </EmbedCodeContent>
        );
      default:
        return <EmptyContent />;
    }
  }

  return (
    <div className="element-wrapper">
      {
        layers.filter(ele => ele.id !== 'main').map((layer, index) => {
          return (
            <div key={index}>
              <ElementWrapper layer={layer}>
                {renderElement(layer)}
              </ElementWrapper>
            </div>
          )
        })
      }
    </div>
  );
}

export default Renderer;
