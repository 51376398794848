import React, { useEffect, useRef, useState } from 'react';
import { HotSpotIcon } from '../../assets/Icons';
import {
  setActiveTool,
  selectActiveTool,
  setActiveLayer,
  selectActiveLayer
} from '../../../redux/toolsSlice';
import {
  updateLayer,
  removeLayerConfig,
  setLayerConfig,
  getLayerObjConfig
} from '../../../redux/stepsSlice';
import { setNextIndex } from '../../../redux/walkthroughPreviewSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Rect, Transformer, Group } from 'react-konva';
import { Html } from 'react-konva-utils';
import ContextMenu from '../context-menu/ContextMenu';
import { setEmbedNextIndex } from '../../../redux/walkthroughEmbedPreviewSlice';

const HotSpot = () => {
  const dispatch = useDispatch();
  const activeTool = useSelector((state) => selectActiveTool(state));
  const [className, setClassName] = useState('tool');

  const setActive = () => {
    dispatch(setActiveTool('hotspot'));
  };

  useEffect(() => {
    if (activeTool === 'hotspot') {
      setClassName('tool active');
    } else {
      setClassName('tool');
    }
  }, [activeTool]);

  return (
    <div
      className={className}
      title="HotSpot"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="HotSpot"
      onClick={setActive}
    >
      <HotSpotIcon />
    </div>
  );
};

export const hotspotLayerDefaults = (id, mouseDown) => {
  const width = 100;
  const height = 50;
  const pos = mouseDown;

  return {
    id: id,
    type: 'hotspot',
    x: pos.x,
    y: pos.y,
    width: width,
    height: height,
    fill: '#11ffff44',
    rotation: 0,
    stroke: '#000000',
    strokeWidth: 1,
    cornerRadius: 0
  };
};

// Published version of RenderSquare
export const RenderPreviewHotspot = ({ layer, embed = false, step, id, onChange }) => {
  const dispatch = useDispatch();

  const nextPage = () => {
    dispatch(embed ? setEmbedNextIndex(id) : setNextIndex());
    // dispatch(setNextIndex());
    onChange()
  };

  const renderNode = () => {
    return (
      <div>
        <Rect
          x={layer.x}
          y={layer.y}
          width={layer.width}
          height={layer.height}
          rotation={layer.rotation}
          key={layer.id}
          onClick={nextPage}
          onMouseOver={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = 'pointer';
          }}
          onMouseOut={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = 'default';
          }}
        />
      </div>
    );
  };

  return <>{renderNode()}</>;
};

// Author version of RenderSquare
export const RenderHotSpot = ({ stepId, layer }) => {
  const shapeRef = useRef();
  const transformerRef = useRef();
  const dispatch = useDispatch();
  const activeLayer = useSelector((state) => selectActiveLayer(state));
  const isSelected =
    activeLayer &&
    activeLayer.layerId === layer.id &&
    activeLayer.stepId === stepId;
  const [menuVisible, setMenuVisible] = useState(false);
  const contextMenuOptions = ['Delete'];
  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'hotspot',
      sectionTitle: 'Element > HotSpot',
      values: [
        {
          id: 'fill',
          title: 'BG Color',
          type: 'color',
          value: layer.fill || '#ffffff'
        },
        {
          id: 'stroke',
          title: 'Border Color',
          type: 'color',
          value: layer.stroke || '#000000'
        },
        {
          id: 'strokeWidth',
          title: 'Border Weight',
          type: 'px-size',
          value: layer.strokeWidth || 0
        },
        {
          id: 'cornerRadius',
          title: 'Border Radius',
          type: 'px-size',
          value: layer.cornerRadius || 0
        }
      ]
    }
  ];

  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const renderContextMenu = () => {
    return (
      <ContextMenu
        options={contextMenuOptions}
        activeLayer={layer}
        stepId={stepId}
        dispatch={dispatch}
        onClick={menuClick}
      />
    );
  };

  const showContextMenu = (e) => {
    e.evt.preventDefault();
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    setMenuVisible(true);
  };

  const menuClick = (e) => {
    setMenuVisible(false);
  };

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    } else {
      if (!activeLayer?.stepId && !activeLayer?.layerId) {
        dispatch(removeLayerConfig());
      }
      setMenuVisible(false);
    }
  }, [isSelected]);

  const handleLayerUpdate = (e) => {
    const newLayer = {
      ...layer,
      x: e.target.x(),
      y: e.target.y()
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const setActive = () => {
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    dispatch(setLayerConfig(defaultConfig));
  };

  return (
    <>
      <Group x={layer.x} y={layer.y} draggable onDragEnd={handleLayerUpdate}>
        <Rect
          width={layer.width}
          height={layer.height}
          rotation={layer.rotation}
          fill={layer.fill}
          key={layer.id}
          onClick={setActive}
          stroke={layer.stroke}
          strokeWidth={layer.strokeWidth}
          cornerRadius={layer.cornerRadius}
          onContextMenu={showContextMenu}
          ref={shapeRef}
        />
        {menuVisible && <Html>{renderContextMenu()}</Html>}
        {isSelected && (
          <Transformer
            ref={transformerRef}
            keepRatio={false}
            ignoreStroke={true}
            boundBoxFunc={(oldBox, newBox) => {
              if (Math.abs(newBox.width) < 2 || Math.abs(newBox.height) < 2) {
                return oldBox;
              }

              dispatch(
                updateLayer({
                  stepId: stepId,
                  layer: {
                    ...layer,
                    width: newBox.width,
                    height: newBox.height
                  }
                })
              );

              return newBox;
            }}
          />
        )}
      </Group>
    </>
  );
};

export default HotSpot;
