import React, { useState, useEffect } from "react";
import LabelAndToggle from "./LabelAndToggle";
import { CheckAnswer, CogIcon, TrashIcon } from "../../../../../assets/icons";
import { v4 as uuidv4 } from 'uuid';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  ELEMENT: 'element',
};

// Answer Item Component
function AnswerItem({ answer, index, handleAnswerChange, toggleCheckAnswer, deleteAnswer, moveAnswer, visibility }) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ELEMENT,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.ELEMENT,
    drop: (item) => {
      moveAnswer(item.index, index);
    },
  });

  return (
    <div key={index} className="item-list-flex-design" ref={(node) => drag(drop(node))}>
      <div className={`flex-third-col-design ${answer.isChecked ? "active" : ""}`}>
        <div className="icon-flex-third-col-design">
          <CogIcon />
        </div>
        <div className="filed-design-form flex-full">
          <input
            type="text"
            className="input-design-form"
            placeholder="Write here..."
            value={answer.value}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
          />
        </div>
        {visibility && (
          <div onClick={() => toggleCheckAnswer(index)} className="question-correct-answer">
            <CheckAnswer />
          </div>
        )}
        <div onClick={() => deleteAnswer(index)} className="button-delete"><TrashIcon /></div>
      </div>
    </div>
  );
}

// Single Question Component that manages its own options
function QuestionItem({ question, index, handleQuestionChange, deleteQuestion, visibility }) {
  const [options, setoptions] = useState(question.options || []);

  useEffect(() => {
    handleQuestionChange(index, { ...question, options });
  }, [options]);

  const handleAnswerChange = (answerIndex, newValue) => {
    setoptions((prev) =>
      prev.map((answer, i) => (i === answerIndex ? { ...answer, value: newValue } : answer))
    );
  };

  const toggleCheckAnswer = (answerIndex) => {
    setoptions((prev) =>
      // prev.map((answer, i) =>
      //   i === answerIndex ? { ...answer, isChecked: !answer.isChecked } : answer
    // )
      prev.map((option, i) => answerIndex === i ? {...option, isChecked: !option.isChecked } : {...option, isChecked: false})
    );

  };

  const deleteAnswer = (answerIndex) => {
    setoptions((prev) => prev.filter((_, i) => i !== answerIndex));
  };

  const addNewOption = () => {
    setoptions((prev) => [
      ...prev,
      { id: uuidv4(), value: "", isChecked: false },
    ]);
  };

  const moveAnswer = (dragIndex, hoverIndex) => {
    const dragAnswer = options[dragIndex];
    setoptions((prev) => {
      const newoptions = [...prev];
      newoptions.splice(dragIndex, 1);
      newoptions.splice(hoverIndex, 0, dragAnswer);
      return newoptions;
    });
  };

  const setAnswer = (e) => {
    setoptions((prev) =>
      prev.map((option, i) => i === 0 ? {...option, isChecked: e } : {...option, isChecked: false})
    );
  }

  return (
    <div className="question-item">
      <div className="question-header">
        <label>{`Question\u00A0${index + 1}`}</label>
        <input
          type="text"
          className="input-design-form"
          placeholder="Question text..."
          value={question.question}
          onChange={(e) =>
            handleQuestionChange(index, { ...question, question: e.target.value })
          }
        />
        <div className="button-delete" onClick={() => deleteQuestion(index)}>
          <TrashIcon />
        </div>
      </div>

      <div className="list-flex-design list-question-design">
        {visibility && <LabelAndToggle title="Set correct answer" checked={options.filter(ele => ele.isChecked).length > 0} setChecked={setAnswer}/>}
        {options.map((answer, idx) => (
          <AnswerItem
            key={idx}
            answer={answer}
            index={idx}
            handleAnswerChange={handleAnswerChange}
            toggleCheckAnswer={toggleCheckAnswer}
            deleteAnswer={deleteAnswer}
            moveAnswer={moveAnswer}
            visibility={visibility}
          />
        ))}
      </div>
      <div className="cover-add-btn-design">
        <button onClick={addNewOption} type="button" className="button-add btn-add-question add-option" >
          Add option
        </button>
      </div>
      <hr className="mb-2"/>
    </div>
  )
}

const MultipleChoiceQuestionAnswer = ({ defaultValue, onChange, visibility = false }) => {
  console.log("defaultValue >>", defaultValue);
  const [questions, setQuestions] = useState(defaultValue ||[
    {
      id: uuidv4(),
      question: "Enter question",
      options: [
        { id: uuidv4(), value: "Option 1", isChecked: false },
        { id: uuidv4(), value: "Option 2", isChecked: false },
      ],
    },
  ]);

  useEffect(() => {
    onChange(questions);
  }, [questions, onChange]);

  const handleQuestionChange = (index, updatedQuestion) => {
    setQuestions((prev) =>
      prev.map((question, i) => (i === index ? updatedQuestion : question))
    );
  };

  const addNewQuestion = () => {
    setQuestions((prev) => [
      ...prev,
      { id: uuidv4(), question: "", options: [] },
    ]);
  };

  const deleteQuestion = (index) => {
    setQuestions((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="questions-list">
      {questions.map((question, idx) => (
        <QuestionItem
          key={question.id}
          question={question}
          index={idx}
          handleQuestionChange={handleQuestionChange}
          deleteQuestion={deleteQuestion}
          visibility={true} // Assuming you always want visibility for options
        />
      ))}
      <div className="cover-add-btn-design">
        <button
          onClick={addNewQuestion}
          type="button"
          style={{width: "100%"}}
          className="button-add btn-add-question d-flex"
        >
          Add Question
        </button>
      </div>
    </div>
  );
}

export default MultipleChoiceQuestionAnswer;