import React, { useState } from 'react';
import { getValue } from '../../pages/utils/tools';

const StepIndicator = ({ steps, index, onChange, configColors }) => {
  const [startIndex, setStartIndex] = useState(0);
  const currentStep = steps[index];
  const maxSteps = 8;

  const setCurrent = (index) => () => {
    onChange(index);
  };

  const tnTextColor = getValue(configColors, 'tn', 'tnTextColor');
  const tnBGColor = getValue(configColors, 'tn', 'tnBGColor');
  const style = {
    '--background-color': tnTextColor || configColors.primary,
    '--primary-color': tnBGColor || configColors.background
  };

  const renderStep = (step, index) => {
    const isFirst = step.id === steps[0].id;
    const currIndex = steps.findIndex((step) => step.id === currentStep.id);
    const activeClass = currIndex + 1 > index ? 'active' : '';

    return (
      <div
        key={index}
        className="step-indicator__item"
        onClick={setCurrent(index)}
      >
        {!isFirst && <div className={`line ${activeClass}`} style={style} />}
        <div className={`step ${activeClass}`} style={style}>
          {index + 1}
        </div>
      </div>
    );
  };

  const shiftIndicator = (direction) => () => {
    if (direction === "left" && startIndex > 0) {
      setStartIndex(startIndex - 1);
    } else if (direction === "right" && startIndex + maxSteps < steps.length) {
      setStartIndex(startIndex + 1);
    }
  }

  const visibleSteps = steps.slice(startIndex, startIndex + maxSteps);

  return (
    <div className="step-indicator">
      <div className="step-indicator__steps">
        <div 
          className={`step-indicator__dash ${startIndex === 0 ? '' : 'active'}`} 
          onClick={shiftIndicator("left")}
        >
        </div>
        {visibleSteps.map((step, index) => renderStep(step, startIndex + index))}
        <div 
          className={`step-indicator__dash ${startIndex + maxSteps >= steps.length ? '' : 'active'}`} 
          onClick={shiftIndicator("right")}
        >
        </div>
      </div>
    </div>
  );
};

export default StepIndicator;
